import Home from './components/Home';
import Navbar from './components/Navbar';

function App() {
  return (
    <>
      <Navbar />
      {/* <Home />   */}
    </>
  );
}

export default App;
