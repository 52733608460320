import React, { useState, useEffect } from 'react'
import logo from '../../src/assets/Gfc (3).png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faRightLeft } from '@fortawesome/free-solid-svg-icons'
import banner from '../../src/assets/banner.png'
import people from '../../src/assets/people.png'
import twox from '../../src/assets/2xcircle.png'
import deal from '../../src/assets/deal-removebg-preview.png'
import ApexCharts from 'apexcharts'
import split from '../../src/assets/split5.png'

const Navbar = () => {

  const [ open, setOpen ] = useState(false);

  const handleChange = ()=> {
    setOpen(!open)
  }

  const options = {
    xaxis: {
        categories: ['Your Investment', 'Your Returns'],
        labels: {
          rotate: 0,
            style: {
              fontSize: '8px',
              colors: 'white', // Adjust font size as needed
              fontWeight: 'bold',

            }
        }
    },
    colors: ['#fff'],
    chart: {
      toolbar: {
        show: false
      },
        type: 'bar'
    },
    
    series: [{
        data: [100, 200]
    }],
    
  tooltip: {
    enabled: true,
    enabledOnSeries: undefined,
    shared: true,
    followCursor: false,
    intersect: false,
    inverseOrder: false,
    custom: undefined,
    hideEmptySeries: true,
    fillSeriesColor: false,
    theme: false,
    style: {
      fontSize: '10px',
      fontFamily: undefined
    },
    onDatasetHover: {
        highlightDataSeries: false,
    },
    x: {
        show: true,
        format: 'dd MMM',
        formatter: undefined,
    },
    y: {
        formatter: undefined,
        title: {
            formatter: (seriesName) => seriesName,
        },
    },
    z: {
        formatter: undefined,
        title: 'Size: '
    },
    marker: {
        show: true,
    },
    items: {
       display: "flex",
    },
    fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
    },
  },

    dataLabels: {
      style: {
        colors: ['#000']
      }
    }
    
  };

  useEffect(()=> {
    const chart = new ApexCharts(document.getElementById('chart'), options)
    chart.render();

    return () => {
      chart.destroy(); // Destroy the chart instance when the component unmounts
    };
  }, [])

  return (
    <>
    
    <section className='bg-gradient-to-r from-blue-300 via-blue-700 to-blue-950'>

      <section className='w-full fixed z-40 bg-gradient-to-r from-blue-400 via-blue-700 to-blue-950'>

        <nav className='flex justify-between px-[5%] md:px-[10%] lg:px-[15%] h-16 md:h-20 p-4 items-center'>

          <img src={logo} alt='logo' className='h-10 md:h-16' />

          <FontAwesomeIcon icon={faBars} className='sm:hidden block h-8 text-white' onClick={handleChange} />

          <div className='hidden gap-5 sm:flex'>

            <a href='https://user.globalfc.app/login' ><button className='px-4 h-8 md:h-12 bg-primarycolor text-white md:text-lg rounded-lg hover:text-primarycolor hover:bg-white'>Sign In</button></a>

            <a href='https://user.globalfc.app/signupverify' ><button className='px-4 h-8 md:h-12 bg-primarycolor text-white md:text-lg rounded-lg hover:text-primarycolor hover:bg-white'>Sign Up</button></a>

          </div>

        </nav>

      </section>

      {open && 
        <section className='fixed top-0 right-[5%] w-96 z-40'>
          <section className='absolute bg-white text-primarycolor right-0 flex flex-col text-left mt-[17%]'>
            <a href='https://user.globalfc.app/login'><p className='hover:bg-primarycolor hover:text-white px-6 py-3 '>Sign In</p></a>
            <a href='https://user.globalfc.app/signupverify'><p className='hover:bg-primarycolor hover:text-white px-6 py-3 '>Sign Up</p></a>
          </section>
        </section>
      }

      <section className='grid grid-cols-1 md:grid-cols-2 px-[5%] md:px-[10%] lg:px-[15%] p-10 pt-[20%] sm:pt-[15%] lg:pt-[7%]'>

        <section>
          <h1 className='font-bold text-xl md:text-3xl xl:text-5xl text-white'>Welcome to Global Fortune Club</h1>
          <p className='text-base md:text-lg xl:text-xl leading-5 md:leading-7 xl:leading-9 text-white py-10'>The premier self-empowerment fortune club committed to helping people attain financial autonomy. We are thrilled to welcome you to our community and look forward to sharing what makes our company distinctive.</p>
        </section>

        <section className='relative'>
          
            <img src={banner} alt='banner' className='h-[100%] w-[100%]'/>
            {/* <img src={twox} alt='banner' className='absolute top-0 h-14 dippam'/>
            <img src={bitcoin} alt='banner' className='absolute top-20 h-10 dippam'/>
            <img src={ethereum} alt='banner' className='absolute top-20 left- ml-96 h-10 dippam'/>
            <img src={dollar} alt='banner' className='absolute -mt-10 h-10 dippam'/> */}
          
        </section>

      </section>

      <section className='bg-group w-[100%] h-[50%] pb-[40%] pt-20 xl:pt-32 bg-cover flex flex-col justify-start text-center'>
          <h1 className='text-xl sm:text-3xl xl:text-5xl font-bold text-primarycolor'>Meet Each Other, Help Each Other</h1>
      </section>

      <section className='flex border-4 border-white m-5 md:m-10 lg:m-20 bg-transparent text-white h-[100%] p-5 sm:p-10 xl:p-20 flex-col gap-10'>
          
          <section className='grid grid-cols-1 md:grid-cols-2 sm:flex-row'>

            <section className='flex justify-center items-center'>
              <img src={people} alt='about us gif' className='md:h-[100%] md:w-[100%] h-[100%] w-[50%]'/>
            </section>

            <section className='flex gap-5 flex-col h-[50%]'>
              <h1 className='font-bold text-2xl md:text-4xl xl:text-6xl'>About Us</h1>
              <p className='text-lg md:text-xl xl:text-3xl leading-5 md:leading-7 xl:leading-9'>Welcome to Global Fortune Club, the leading self-helping fortune club dedicated to helping people achieve financial independence.</p>
              <p className='text-lg md:text-xl xl:text-3xl leading-5 md:leading-7 xl:leading-9'>At Global Fortune Club, we believe that everyone has the potential to achieve financial success and independence.</p>
            </section>          

          </section>

          <section className='flex flex-col lg:flex-row w-[100%] gap-10 md:gap-20'>
            <section className='flex flex-col justify-center items-center text-center w-[100%] gap-4 border border-white p-5'>
              <FontAwesomeIcon icon={faRightLeft} className='h-10'/>
              <p className='font-bold text-base md:text-2xl'>Reliable Performance</p>
              <p className='text-base md:text-xl'>At Global Fortune Club, our commitment to reliability ensures swift and accurate results, empowering our members with confidence.</p>
            </section>
            <section className='flex flex-col justify-center items-center text-center w-[100%] gap-4 border border-white p-5'>
              <FontAwesomeIcon icon={faRightLeft} className='h-10'/>
              <p className='font-bold text-base md:text-2xl'>Transparent Trustworthiness</p>
              <p className='text-base md:text-xl'>We prioritize transparency and trustworthiness in every aspect of our operations, fostering a secure environment for our members.</p>
            </section>
            <section className='flex flex-col justify-center items-center text-center w-[100%] gap-4 border border-white p-5'>
              <FontAwesomeIcon icon={faRightLeft} className='h-10'/>
              <p className='font-bold text-base md:text-2xl'>Efficient Service</p>
              <p className='text-base md:text-xl'>Our streamlined processes and efficient service guarantee prompt and hassle-free experiences for our members, to maximize their potential.</p>
            </section>
          </section>

      </section>

      {/* <section className='w-[100%] h-[100%] pb-[50%] pt-20 xl:pt-32 bg-cover flex flex-col justify-start text-center'>
          <h1 className='text-xl sm:text-3xl xl:text-5xl font-bold text-white'>More People, More Benefits.</h1>
          <img src={peoples} alt='peoples image' className='md:h-[50%] md:w-[100%] h-[50%] w-[50%]'/>
      </section> */}

      <section className='p-5 md:p-10 w-[100%] h-fit text-white'>

          <h1 className='font-bold text-3xl md:text-4xl xl:text-6xl px-[5%]'>How It Works</h1>

          <section className='flex justify-end text-center flex-col px-[5%] md:px-[15%] lg:px-[25%]'>

            <section className='grid grid-cols-2 py-[10%]'>

              <section className='flex flex-col text-left'>
                <h1 className='font-bold text-xl md:text-4xl'>Step 1:</h1>
                <p className='font-semibold text-base md:text-2xl'>Buy a Slot!</p>
              </section>
              
              <section className='flex justify-center'>
                <img src={deal} alt='image 1' className='' />
              </section>

            </section>

            <section className='grid grid-cols-2 py-[10%] gap-5'>

              <img src={split} alt='image 1' />

              <section  className='flex flex-col text-right'>
                <h1 className='font-bold text-xl md:text-4xl'>Step 2:</h1>
                <p className='font-semibold text-base md:text-2xl'>Get Splits, for the consecutive slots bought after you.</p>
              </section>

            </section>

            <section className='grid grid-cols-2 py-[10%]'>

              <section className='flex flex-col text-left'>
                <h1 className='font-bold text-xl md:text-4xl'>Step 3:</h1>
                <p className='font-semibold text-base md:text-2xl'>Exit when your investment becomes doubled!</p>
              </section>

              <section className='flex justify-end'>
                <img src={twox} alt='image 1' className='h-[100%] w-[70%]'/>
              </section>
              {/* <FontAwesomeIcon icon={faMarsDouble } /> */}

            </section>

          </section>
         
      </section>

      <section className='grid grid-cols-1 md:grid-cols-2 justify-between items-center text-center p-10 mt-[5%] px-[3%] md:px-[10%]'>

        <section id='chart' className='h-[100%] w-[100%]'>

        </section>
        
        <section className='text-center text-white md:text-end justify-center items-center md:items-end flex flex-col gap-5'>

          <h1 className='font-bold text-xl md:text-3xl xl:text-5xl text-nowrap'>Do Yourself a Favor</h1>
          <h1 className='font-bold text-xl xl:text-2xl text-nowrap'>Buy a slot and see the Magic!</h1>
          
        </section>

      </section>

      <section className='bg-gradient-to-r from-blue-600 to-blue-900 text-white flex text-center justify-center p-5'>
        <p className='font-semibold'>Global Fortune Club ©️ All Rights Reserved</p>
      </section>
      
    </section>

    </>
  )
}

export default Navbar
