import React, { useEffect, useState } from 'react'
import logo from '../assets/Gfc.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faRightLeft } from '@fortawesome/free-solid-svg-icons'
import people from '../../src/assets/people.png'
import aboutUsGif from '../assets/gfc icons (1).gif'
import ApexCharts from 'apexcharts'

const Home = () => {

  const [ open, setOpen ] = useState(false);

  const options = {
    xaxis: {
        categories: ['Your Investment', 'Your Returns'],
        labels: {
          rotate: 0,
            style: {
              fontSize: '20px',
              colors: 'white', // Adjust font size as needed
              fontWeight: 'bold',
            }
        }
    },
    chart: {
      toolbar: {
        show: false
      },
        type: 'bar'
    },
    series: [{
        data: [100, 200]
    }]
};

  // useEffect(()=> {
  //   const chart = new ApexCharts(document.getElementById('chart'), options)
  //   chart.render();

  //   return () => {
  //     chart.destroy(); // Destroy the chart instance when the component unmounts
  // };
  // }, [])

  return (
    <>
      <section className='bg-black'>
        
        <section className='flex justify-between text-center items-center px-[5%] p-5 bg-primarycolor text-white h-8 md:h-20'>

            <img src={logo} alt='logo' className='h-8 md:h-12 w-12'/>

            <ul className='hidden lg:flex gap-10 font-bold text-xl'>
              <li className='un hover:text-black'>Home</li>
              <li className='un hover:text-black'>About Us</li>
              <li className='un hover:text-black'>Mission</li>
              <li className='un hover:text-black'>Vision</li>
              <li className='un hover:text-black'>How it Works</li>
              <li className='un hover:text-black'>Contact Us</li>
            </ul>

            <button className='hidden lg:block border border-white bg-transparent rounded-lg shadow-2xl shadow-black text-white p-1 w-20 h-12 text-lg hover:bg-white hover:text-primarycolor transition-all ease-in-out'>Login</button>

            <FontAwesomeIcon icon={faBars} className='block lg:hidden h-8 w-8 text-white' onClick={()=> {setOpen(!open)}}/> 

        </section>

          {open && 
            <>
              <section className='absolute z-10 w-full left-0 right-0 '>
                <ul className='flex flex-col text-center bg-primarycolor'>
                  <li className='hover:bg-white p-3 font-semibold text-white hover:text-primarycolor'>Home</li>
                  <li className='hover:bg-white p-3 font-semibold text-white hover:text-primarycolor'>About Us</li>
                  <li className='hover:bg-white p-3 font-semibold text-white hover:text-primarycolor'>Mission</li>
                  <li className='hover:bg-white p-3 font-semibold text-white hover:text-primarycolor'>Vision</li>
                  <li className='hover:bg-white p-3 font-semibold text-white hover:text-primarycolor'>How it Works</li>
                  <li className='hover:bg-white p-3 font-semibold text-white hover:text-primarycolor'>Contact Us</li>
                  <li className='hover:bg-white p-3 font-semibold text-white hover:text-primarycolor'>Login</li>
                </ul>
              </section>
            </>
          }

        <section className='bg-black text-white h-full w-full grid grid-cols-1 sm:grid-cols-2 bg-banner-img bg-cover'>
          
          <section className='flex flex-col gap-5 p-5 sm:p-10 xl:p-20'>
            
            <p className='uppercase text-base md:text-lg'>Easy And Efficient</p>
            
            <h1 className='font-bold text-3xl md:text-5xl xl:text-7xl'>Welcome To Global Fortune Club</h1>
            
            <p className='text-base md:text-lg xl:text-xl leading-5 md:leading-7 xl:leading-9'>The premier self-empowerment fortune club committed to helping people attain financial autonomy. We are thrilled to welcome you to our community and look forward to sharing what makes our company distinctive.</p>
          
          </section>

          <section className='flex justify-center items-center'>
              <img src={people} alt='' className='h-fit'/>
          </section>  
        
        </section>

        <section className='flex border-4 border-white m-5 md:m-10 lg:m-20 bg-black text-white h-[100%] p-5 sm:p-10 xl:p-20 flex-col gap-10'>
          
          <section className='flex flex-col sm:flex-row'>

            <section className='flex gap-5 flex-col h-[50%]'>
              <h1 className='font-bold text-2xl md:text-4xl xl:text-6xl'>About Us</h1>
              <p className='text-lg md:text-xl xl:text-3xl leading-5 md:leading-7 xl:leading-9'>Welcome to Global Fortune Club, the leading self-helping fortune club dedicated to helping people achieve financial independence.</p>
              <p className='text-lg md:text-xl xl:text-3xl leading-5 md:leading-7 xl:leading-9'>At Global Fortune Club, we believe that everyone has the potential to achieve financial success and independence.</p>
            </section>

            <section className='flex justify-center items-center'>
              <img src={aboutUsGif} alt='about us gif' className='h-[50%] '/>
            </section>

          </section>

          <section className='flex flex-col lg:flex-row w-[100%] gap-20'>
            <section className='flex flex-col justify-center items-center text-center w-[100%] gap-4 border border-white p-5'>
              <FontAwesomeIcon icon={faRightLeft} className='h-10'/>
              <p className='font-bold text-base md:text-2xl'>Reliable Performance</p>
              <p className='text-base md:text-xl'>At Global Fortune Club, our commitment to reliability ensures swift and accurate results, empowering our members with confidence.</p>
            </section>
            <section className='flex flex-col justify-center items-center text-center w-[100%] gap-4 border border-white p-5'>
              <FontAwesomeIcon icon={faRightLeft} className='h-10'/>
              <p className='font-bold text-base md:text-2xl'>Transparent Trustworthiness</p>
              <p className='text-base md:text-xl'>We prioritize transparency and trustworthiness in every aspect of our operations, fostering a secure environment for our members.</p>
            </section>
            <section className='flex flex-col justify-center items-center text-center w-[100%] gap-4 border border-white p-5'>
              <FontAwesomeIcon icon={faRightLeft} className='h-10'/>
              <p className='font-bold text-base md:text-2xl'>Efficient Service</p>
              <p className='text-base md:text-xl'>Our streamlined processes and efficient service guarantee prompt and hassle-free experiences for our members, to maximize their potential.</p>
            </section>
          </section>

        </section>

        <section className='p-5 md:p-10 w-[100%] h-fit text-white'>

          <h1 className='font-bold text-3xl md:text-4xl xl:text-6xl px-[5%]'>How It Works</h1>

          <section className='grid grid-cols-1 md:grid-cols-2 justify-between items-center text-center p-10 mt-[5%]'>

            <section id='chart' className='h-[100%] w-[100%]'>

            </section>
            
            <section className='text-right flex flex-col gap-10'>

              <h1 className='font-bold text-lg md:text-3xl xl:text-5xl text-nowrap'>Do Yourself a Favor</h1>
              <h1 className='font-bold text-base md:text-xl xl:text-2xl text-nowrap'>Buy a slot and see the Magic!</h1>
              
            </section>

          </section>
          
        </section>

      </section>
  </>
  )
}

export default Home
